
const bannerCollapse = () => {
  const banner = document.getElementById('region-dom-tom-catch')
  const banner2 = document.getElementById('region-dom-tom-catch-2')
  if (banner && banner2) {
    banner.addEventListener('click', (event) => {
      banner.parentNode.parentNode.classList.add('d-none')
      banner2.parentNode.parentNode.classList.remove('d-none')
    })
    banner2.addEventListener('click', (event) => {
      banner2.parentNode.parentNode.classList.add('d-none')
      banner.parentNode.parentNode.classList.remove('d-none')
    })
    displayBannerManage(banner)
    window.addEventListener("resize", (event) => {
      displayBannerManage(banner);
    });

  }
}

const displayBannerManage = (banner) => {
    if (window.innerWidth <= 990) {
      banner.parentNode.parentNode.classList.add('d-none')
    } else {
      banner.parentNode.parentNode.classList.remove('d-none')
    }
}



export { bannerCollapse }
