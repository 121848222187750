import { dsfr } from '@gouvfr/dsfr/dist/dsfr/dsfr.module.min.js'

require("trix")
require("@rails/actiontext")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import { set_storage } from 'components/set_storage.js'
import { bannerCollapse } from 'components/map/domTom/banner.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import "bootstrap";
import Trix from "trix";
document.addEventListener('turbolinks:load', () => {

  Trix.config.blockAttributes.heading2 = {
    tagName: 'h2',
    terminal: true,
    breakOnReturn: true,
    group: false
  }
  Trix.config.blockAttributes.heading3 = {
    tagName: 'h3',
    terminal: true,
    breakOnReturn: true,
    group: false
  }

  addEventListener("trix-initialize", event => {
    const { toolbarElement } = event.target

    addOptionsHeading(toolbarElement);

  })

  const addOptionsHeading = (toolbarElement) => {
    const h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]")
    const heading2Add = document.getElementById('heading2-add');
    const heading3Add = document.getElementById('heading3-add');

    if (heading3Add) {
    } else {
      h1Button.insertAdjacentHTML("afterend", `
          <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="" id="heading3-add">H3</button>
        `)
    }
    if (heading2Add) {
    } else {
      h1Button.insertAdjacentHTML("afterend", `
        <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="" id="heading2-add">H2</button>
      `)
    }
  }

  const toolbarElement = document.querySelector("trix-toolbar");
  if (toolbarElement) {
    addOptionsHeading(toolbarElement);
  }



});
